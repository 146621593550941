body {
    margin: 0;
}
@media screen and (max-width: 1000px){
    .hideMobile {
        display: none;
    }
    .fullwidthmobile {
        width: 100% !important;
    }
}

:where(.css-dev-only-do-not-override-1gpikk6).ant-pagination .ant-pagination-item {
  border-radius: 0; /* Set border radius to 0 */
}
.mobilemenu {
    display: none;
}
@media screen and (max-width: 1000px){
    .sider {
        display: none;
    }
    .mobilemenu {
        display: block;
    }
}
@media screen and (max-width: 1300px){
    .selectResponsive {
        width: 100%;
    }
    .mobileColLG {
        flex-direction: column;
    }
}
@media screen and (max-width: 700px){
    .mobileColSM {
        flex-direction: column;
    }
    .mobileColSM .ant-space-item {
        width: 100%;
    } 
    .mobileColSM .ant-space-item .ant-select {
        width: 100% !important;
    }
}

.ant-table-content {
    overflow-x: auto;
}
.ant-breadcrumb-link.navigate {
    cursor: pointer;
}
.ant-breadcrumb-link.navigate:hover {
    text-decoration: underline;
}

.ant-input-group-addon {
    background-color: #000000 !important;
    color: white !important;
}

.fullwidth > .ant-space-item {
    width: 100%
}

.permissions > .ant-space-item:first-child {
    width: auto;
    min-width: 400px;
}

.nodeFullWidth > [title] {
    width: 100%
}

.fullHeight > div {
    height: 100%;
}

.nomarginchild > div {
    margin: 0 0 0 0 !important;
}

.editticket .ql-container.ql-snow {
    max-height: 150px;
    overflow: auto;
}
a {
    color: #000000;
    text-decoration: underline;
}
a:hover {
    color: #0e0e0e
}

@media screen and (max-width: 500px){
    .smallResCol {
        flex-direction: column;
    }
}
@media screen and (max-width: 800px){
    .hideOn800px {
        display: none;
    }
    .shopOn800px {
        display: block;
    }
    .twoColumnsLayout {
        grid-template-columns: 100% !important;
    }
}
@media screen and (min-width: 801px){
    .hideOn800px {
        display: block;
    }
    .shopOn800px {
        display: none;
    }
}

.events {
    margin: 0;
    padding: 0; 
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }

  .ant-tabs-nav {
    margin: 0 0 0 0 !important
  }

  .fastEnters .ant-tabs-tab{
    padding: 0px 20px 0px 20px;
    color: black;
    margin: 0 0 0 0 !important; 
    border: 1px solid #DDDDDD
  }
  .fastEnters .ant-tabs-tab span.anticon {
    margin-right:0px !important;
  }
  .fastEnters [data-node-key=nowa] {
    padding: 12px 20px 12px 20px !important;
  }
  .fastEnters .ant-tabs-tab-btn {
    color: black !important;

  }

  .fastEnters .ant-tabs-tab-active {
    background-color: white;
  }

  .elipses {
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }
  
  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }

  .newform .ant-form-item {
    margin-bottom: 20px
}
  .newform .ant-form-item .ant-row {
    display: block;
}


.row3 {
    display: flex;
    width: 100%;
    gap: 20px
}
.row3 > * {
    width: 100%
}

@media screen and (max-width: 750px){
    .row3 {
        display: block;
    }
}

.newform .ant-picker {
    width: 100%;
}
.ant-picker-calendar-date-content {
    height: 40px !important;
}
.ant-form-item {
    margin-bottom: 10px;
}
.flexFormCol2 > * {
    width: 100%;
}
.flexFormCol2 {
    display: flex;
    width: 100%;
    gap: 20px;
}
.ql-editor {
    min-height: 100px; /* Adjust based on your line-height and padding */
}


.nonvisited * {
    font-weight: 700;
}
.showMobile {
    display: none;
}
.hideMobile {
    display: flex;
}
@media screen and (max-width: 1500px){
    .showMobile {
        display: block;
    }
    .hideMobile {
        display: none !important;
    }
}
.hideMobile {
    flex: 1;
}

.ant-table-cell {
    vertical-align:center;
}

.min-200 {
    min-width: 150px;
}

.openingEditing {
    position: absolute;
    top: -30px;
    height: 30px;
    background: white;
    padding: 0 15px;
    border-radius: 5px 5px 0 0;
    right: 10px;
    border-width: 1px 1px 1px 1px;
    border-color: #0000003d;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reminder-btn:hover {
    background-color: #BD8136;
    border-radius: 5px;
    transition-duration: 0.2s;
    padding: 5px 10px !important;
    color: white !important;
}

.mainpostcontent {
    width: calc(100% - 300px)
}
.sidebaropener {
    display: none;
}
.button-sidebaropener {
    display: none;
}
@media screen and (max-width: 1200px){
    .mainpostcontent {
        width: calc(100%) !important
    }   
    .button-sidebaropener {
        display: flex;
        gap: 10px;
        align-items: center;
        position: absolute;
        z-index: 100;
        right: 25px;
        top: 10px;
        height: fit-content !important;
        flex: none;
        padding: 5px 20px;
        background-color: black;
        color: white;
        border-radius: 1px;
        font-weight: 600;
    }
    .sidebar-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        display: none !important;
        background-color: white;
    }
    .sidebaropener {
        padding: 5px 10px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        background: black;
        margin-bottom: 10px;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: -20px;
        font-weight: 600;
        color: white;
        
    }
    .sidebar-wrapper.side-opened {
        display: block !important;
    }
    .post-title {
        margin-top: 32px
    }
}
.datastructure-post {
    display: grid;
    grid-template-columns: 400px 100px;
}
@media screen and (max-width: 620px){
    .datastructure-post {
        display: block;
        grid-template-columns: 400px 100px;
    }   
}